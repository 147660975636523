/**
 * File generated by js-routes 2.2.4
 * Based on Rails 7.0.4.3 routes of Crmdental::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /aging(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const aging_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"aging"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/2fa/resend(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_2fa_resend_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"2fa"],[2,[7,"/"],[2,[6,"resend"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/2fa/verification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_2fa_verification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"2fa"],[2,[7,"/"],[2,[6,"verification"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/aging/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_aging_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"aging"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/appointment_per_employees/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_appointment_per_employees_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"appointment_per_employees"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/async_agents/data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_async_agents_data_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"async_agents"],[2,[7,"/"],[2,[6,"data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/async_agents/last_id(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_async_agents_last_id_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"async_agents"],[2,[7,"/"],[2,[6,"last_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/authorization/logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_authorization_logout_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"authorization"],[2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/authorization/password_reset(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_authorization_password_reset_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"authorization"],[2,[7,"/"],[2,[6,"password_reset"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/authorization/recovery(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_authorization_recovery_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"authorization"],[2,[7,"/"],[2,[6,"recovery"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/authorization/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_authorization_sign_in_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"authorization"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/call_logs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_call_log_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"call_logs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/call_logs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_call_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"call_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/call_logs/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_call_logs_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"call_logs"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/call_logs/send_sms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_call_logs_send_sms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"call_logs"],[2,[7,"/"],[2,[6,"send_sms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/chat/create_group(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_chat_create_group_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"create_group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/chat/delete_group(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_chat_delete_group_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"delete_group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/chat/mark_as_read(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_chat_mark_as_read_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"mark_as_read"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/chat/messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_chat_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"messages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/chat/send_message(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_chat_send_message_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"send_message"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/chat/send_message_diffusion(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_chat_send_message_diffusion_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"send_message_diffusion"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/chat/update_group(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_chat_update_group_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"update_group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/chat/update_name_group(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_chat_update_name_group_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"update_name_group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/chat/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_chat_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"chat"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/collection/insurance_payment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_collection_insurance_payment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"collection"],[2,[7,"/"],[2,[6,"insurance_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/collection/insurance_payment/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_collection_insurance_payment_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"collection"],[2,[7,"/"],[2,[6,"insurance_payment"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/collection/patient_payment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_collection_patient_payment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"collection"],[2,[7,"/"],[2,[6,"patient_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/collection/patient_payment/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_collection_patient_payment_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"collection"],[2,[7,"/"],[2,[6,"patient_payment"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/collection/search_patient(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_collection_search_patient_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"collection"],[2,[7,"/"],[2,[6,"search_patient"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/communication/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_communication_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"communication"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/communication/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_communication_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"communication"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/communication/list_page(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_communication_list_page_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"communication"],[2,[7,"/"],[2,[6,"list_page"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/communication/delete/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_communications_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"communication"],[2,[7,"/"],[2,[6,"delete"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/daily_productions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_daily_production_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"daily_productions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/daily_productions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_daily_productions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"daily_productions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/daily_productions/dashboards/historical_production(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_daily_productions_dashboards_historical_production_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"daily_productions"],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"historical_production"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/daily_productions/dashboards/kpis(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_daily_productions_dashboards_kpis_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"daily_productions"],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"kpis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/daily_productions/dashboards/monthly_production_per_office(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_daily_productions_dashboards_monthly_production_per_office_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"daily_productions"],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"monthly_production_per_office"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/daily_productions/dashboards/net_production(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_daily_productions_dashboards_net_production_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"daily_productions"],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"net_production"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/daily_productions/dashboards/record_productivity_information(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_daily_productions_dashboards_record_productivity_information_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"daily_productions"],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"record_productivity_information"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/daily_productions/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_daily_productions_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"daily_productions"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/dashboards/appointment_day(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_dashboards_appointment_day_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"appointment_day"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/dashboards/appointment_day_employee(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_dashboards_appointment_day_employee_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"appointment_day_employee"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/dashboards/appointments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_dashboards_appointments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"appointments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/dashboards/appointments_per_office(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_dashboards_appointments_per_office_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"appointments_per_office"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/dashboards/collections_patients_and_insurance_payments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_dashboards_collections_patients_and_insurance_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"collections_patients_and_insurance_payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/dashboards/google_reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_dashboards_google_reviews_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"google_reviews"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/dashboards/graph_insurance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_dashboards_graph_insurance_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"graph_insurance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/dashboards/graph_language(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_dashboards_graph_language_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"graph_language"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/dashboards/kpis(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_dashboards_kpis_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"kpis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/dashboards/referral(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_dashboards_referral_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"referral"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/dashboards/report_employee(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_dashboards_report_employee_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"report_employee"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/collection/insurance_payment/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_delete_collection_insurance_payment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"collection"],[2,[7,"/"],[2,[6,"insurance_payment"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/collection/patient_payment/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_delete_collection_patient_payment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"collection"],[2,[7,"/"],[2,[6,"patient_payment"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/ecommerces(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_ecommerces_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"ecommerces"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/ecommerces/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_ecommerces_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"ecommerces"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/ecommerces/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_ecommerces_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"ecommerces"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/employees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_employee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/employee_times/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_employee_time_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employee_times"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/employee_times(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_employee_times_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employee_times"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/employee_times/adjusted(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_employee_times_adjusted_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employee_times"],[2,[7,"/"],[2,[6,"adjusted"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/employee_times/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_employee_times_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employee_times"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_employees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/employees/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_employees_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/external_sales/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_external_sales_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"external_sales"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/external_sales_receiver(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_external_sales_receiver_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"external_sales_receiver"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/interface/form_delete_file/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_form_delete_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"interface"],[2,[7,"/"],[2,[6,"form_delete_file"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/interface/form(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_forms_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"interface"],[2,[7,"/"],[2,[6,"form"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/interface/forms_destroy/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_forms_delete_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"interface"],[2,[7,"/"],[2,[6,"forms_destroy"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/google_ads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_google_ads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"google_ads"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/google_ads/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_google_ads_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"google_ads"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/receive_automated_google_business_insights(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_google_business_insights_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"receive_automated_google_business_insights"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/google_business_insights/graph(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_google_business_insights_graph_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"google_business_insights"],[2,[7,"/"],[2,[6,"graph"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/google_business_insights/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_google_business_insights_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"google_business_insights"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/google_reviews/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_google_review_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"google_reviews"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/google_reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_google_reviews_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"google_reviews"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/google_reviews/comments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_google_reviews_comments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"google_reviews"],[2,[7,"/"],[2,[6,"comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/google_reviews/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_google_reviews_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"google_reviews"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/google_reviews/list_sms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_google_reviews_list_sms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"google_reviews"],[2,[7,"/"],[2,[6,"list_sms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/google_reviews/send_sms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_google_reviews_send_sms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"google_reviews"],[2,[7,"/"],[2,[6,"send_sms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/healthy_sales/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_healthy_sale_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"healthy_sales"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/healthy_sales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_healthy_sales_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"healthy_sales"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/healthy_sales/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_healthy_sales_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"healthy_sales"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/hygienist_average_productions/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_hygienist_average_productions_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"hygienist_average_productions"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/interface/image(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_image_update_inteface_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"interface"],[2,[7,"/"],[2,[6,"image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/income_insurances/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_income_insurances_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"income_insurances"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/inform_payment/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_inform_payment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"inform_payment"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/inform_payment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_inform_payment_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"inform_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/inform_payment/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_inform_payment_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"inform_payment"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/inform_payment/verify_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_inform_payment_verify_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"inform_payment"],[2,[7,"/"],[2,[6,"verify_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/insurances/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_insurance_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"insurances"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/insurances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_insurances_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"insurances"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/last_notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_last_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"last_notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/leads/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_lead_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"leads"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/leads_comment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_leads_comment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"leads_comment"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/leads/dashboard_leads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_leads_dashboard_leads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"leads"],[2,[7,"/"],[2,[6,"dashboard_leads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/leads/dashboard_leads_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_leads_dashboard_leads_table_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"leads"],[2,[7,"/"],[2,[6,"dashboard_leads_table"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/leads/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_leads_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"leads"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/leads_receiver(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_leads_receiver_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"leads_receiver"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/interface/list_form(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_list_forms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"interface"],[2,[7,"/"],[2,[6,"list_form"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/list_forms_patients(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_list_forms_patients_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"list_forms_patients"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/list_notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_list_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"list_notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/messenger/messages/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_messenger_messages_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"messenger"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/messenger/messages/read(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_messenger_messages_read_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"messenger"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"read"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/messenger/patients/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_messenger_patients_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"messenger"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/meta_ads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_meta_ads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"meta_ads"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/meta_ads/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_meta_ads_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"meta_ads"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/new_patients/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_new_patients_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"new_patients"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/interface/notification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_notification_setting_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"interface"],[2,[7,"/"],[2,[6,"notification"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/offices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_office_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"offices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/offices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_offices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"offices"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/offices/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_offices_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"offices"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/offices/list_select(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_offices_list_select_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"offices"],[2,[7,"/"],[2,[6,"list_select"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/offices/update/office_select(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_offices_update_office_select_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"offices"],[2,[7,"/"],[2,[6,"update"],[2,[7,"/"],[2,[6,"office_select"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/online(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_online_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"online"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/online_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_online_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"online_users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/open_dental/break_appointments/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_open_dental_break_appointments_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"open_dental"],[2,[7,"/"],[2,[6,"break_appointments"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/open_dental/doctor_production/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_open_dental_doctor_production_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"open_dental"],[2,[7,"/"],[2,[6,"doctor_production"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/open_dental/patient_history_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_open_dental_patient_history_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"open_dental"],[2,[7,"/"],[2,[6,"patient_history_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/open_dental/patient_history_payment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_open_dental_patient_history_payment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"open_dental"],[2,[7,"/"],[2,[6,"patient_history_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/open_dental/patient/update_file_xls(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_open_dental_patient_update_file_xls_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"open_dental"],[2,[7,"/"],[2,[6,"patient"],[2,[7,"/"],[2,[6,"update_file_xls"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/open_dental/patient/update_file_xls_delete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_open_dental_patient_update_file_xls_delete_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"open_dental"],[2,[7,"/"],[2,[6,"patient"],[2,[7,"/"],[2,[6,"update_file_xls_delete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/open_dental/patients/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_open_dental_patients_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"open_dental"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/open_dental_payment_total/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_open_dental_payment_total_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"open_dental_payment_total"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/open_dental/update_file_xls(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_open_dental_update_file_xls_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"open_dental"],[2,[7,"/"],[2,[6,"update_file_xls"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/open_dental/update_file_xls_delete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_open_dental_update_file_xls_delete_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"open_dental"],[2,[7,"/"],[2,[6,"update_file_xls_delete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/patients/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_patient_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/patient_dentrix/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_patient_dentrix_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"patient_dentrix"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/patient_forms/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_patient_forms_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"patient_forms"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/patient/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_patient_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"patient"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/patient/list_contact(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_patient_list_contact_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"patient"],[2,[7,"/"],[2,[6,"list_contact"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/patient/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_patient_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"patient"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/patient/table_record(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_patient_table_record_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"patient"],[2,[7,"/"],[2,[6,"table_record"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/plan_contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_plan_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"plan_contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/plan_contracts/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_plan_contracts_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"plan_contracts"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/plan_contracts_receiver(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_plan_contracts_receiver_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"plan_contracts_receiver"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/procedural_codes/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_procedural_codes_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"procedural_codes"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/procedural_codes/delete/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_procedural_codes_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"procedural_codes"],[2,[7,"/"],[2,[6,"delete"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/procedural_codes/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_procedural_codes_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"procedural_codes"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/procedural_codes/update/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_procedural_codes_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"procedural_codes"],[2,[7,"/"],[2,[6,"update"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/profiles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_profile_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/group_ad_google_ads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_receive_automated_group_ad_google_ads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"group_ad_google_ads"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/receive_automated_voicemails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_receive_automated_voicemails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"receive_automated_voicemails"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/interface/record_delete_/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_record_send_delete_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"interface"],[2,[7,"/"],[2,[6,"record_delete_"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/interface/send_record(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_send_record_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"interface"],[2,[7,"/"],[2,[6,"send_record"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms_campaigns/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_campaigns_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms_campaigns"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms_campaigns/create_list_contact(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_campaigns_create_list_contact_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms_campaigns"],[2,[7,"/"],[2,[6,"create_list_contact"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms_campaigns/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_campaigns_delete_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms_campaigns"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms_campaigns/list_to_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_campaigns_list_to_table_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms_campaigns"],[2,[7,"/"],[2,[6,"list_to_table"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms_campaigns/query_balance_campaign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_campaigns_query_balance_campaign_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms_campaigns"],[2,[7,"/"],[2,[6,"query_balance_campaign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms_campaigns/query_price_campaign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_campaigns_query_price_campaign_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms_campaigns"],[2,[7,"/"],[2,[6,"query_price_campaign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms_campaigns/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_campaigns_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms_campaigns"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms/change_color(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_change_color_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms"],[2,[7,"/"],[2,[6,"change_color"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms/change_tag(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_change_tag_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms"],[2,[7,"/"],[2,[6,"change_tag"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms/create_diffusion(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_create_diffusion_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms"],[2,[7,"/"],[2,[6,"create_diffusion"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms/notify(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_notify_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms"],[2,[7,"/"],[2,[6,"notify"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms/read(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_read_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms"],[2,[7,"/"],[2,[6,"read"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms/receive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_receive_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms"],[2,[7,"/"],[2,[6,"receive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms/receive/clicksend(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_receive_clicksend_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms"],[2,[7,"/"],[2,[6,"receive"],[2,[7,"/"],[2,[6,"clicksend"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms_records(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_records_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms_records"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/sms_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sms_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/sms/unread(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sms_unread_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sms"],[2,[7,"/"],[2,[6,"unread"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/staff/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_staff_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/staff/delete/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_staff_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"delete"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/staff/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_staff_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/staff/update/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_staff_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"update"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/timelines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_timelines_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"timelines"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/interface/query_type(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_type_record_last_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"interface"],[2,[7,"/"],[2,[6,"query_type"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/notifications/read(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_update_all_notification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[6,"read"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/collection/insurance_payment/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_update_collection_insurance_payment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"collection"],[2,[7,"/"],[2,[6,"insurance_payment"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/collection/patient_payment/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_update_collection_patient_payment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"collection"],[2,[7,"/"],[2,[6,"patient_payment"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/interface/update_form_file/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_update_form_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"interface"],[2,[7,"/"],[2,[6,"update_form_file"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/healthy_sales/status/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_update_status_healthy_sales_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"healthy_sales"],[2,[7,"/"],[2,[6,"status"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/voicemails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_voicemail_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"voicemails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/voicemails/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_voicemails_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"voicemails"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/webex/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_webex_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"webex"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /appointment_per_employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const appointment_per_employees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"appointment_per_employees"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /export_call_log(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const call_logs_export_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"export_call_log"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const call_logs_new_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /records(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const call_logs_records_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"records"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /sms_records(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const call_logs_sms_records_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sms_records"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /collections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const collections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"collections"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /daily_productions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const daily_productions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"daily_productions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /daily_productions/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const daily_productions_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"daily_productions"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /daily_productions/daily_productions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const daily_productions_export_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"daily_productions"],[2,[7,"/"],[2,[6,"daily_productions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ecommerces(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecommerces_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecommerces"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /call_logs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_call_log_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"call_logs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /daily_productions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_daily_production_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"daily_productions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /employees/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_employee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /healthy_sales/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_healthy_sale_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"healthy_sales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /leads/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_lead_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"leads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /offices/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_office_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"offices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /patients/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_patient_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"patients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /procedural_codes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_procedural_code_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"procedural_codes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /profile/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_profile_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /employees/show/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const employee_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[6,"show"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /employee_time_adjusted(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const employee_time_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"employee_time_adjusted"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /employee_time(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const employee_time_adjusted_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"employee_time"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const employees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /inform_payment/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_inform_payment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"inform_payment"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /export_open_avarage_productions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_open_avarage_productions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"export_open_avarage_productions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /export_open_dental_aging(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_open_dental_aging_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"export_open_dental_aging"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /export_open_dental_break_appointment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_open_dental_break_appointment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"export_open_dental_break_appointment"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /export_open_dental_doctor_production(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_open_dental_doctor_production_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"export_open_dental_doctor_production"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /export_open_dental/patient_history/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_open_dental_patient_history_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"export_open_dental"],[2,[7,"/"],[2,[6,"patient_history"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /export_open_dental_patient_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_open_dental_patient_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"export_open_dental_patient_list"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /export_open_dental/patient_payment/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_open_dental_patient_payment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"export_open_dental"],[2,[7,"/"],[2,[6,"patient_payment"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /export_open_income_insurances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_open_income_insurances_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"export_open_income_insurances"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /export_open_new_patient(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_open_new_patient_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"export_open_new_patient"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /export_patients(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_patients_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"export_patients"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /export_webex(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_webex_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"export_webex"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /forbidden(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const forbidden_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"forbidden"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /generals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"generals"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /google_ads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const google_ads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"google_ads"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /google_business_insights(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const google_business_insights_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"google_business_insights"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /google_reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const google_reviews_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"google_reviews"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /export_google_reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const google_reviews_export_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"export_google_reviews"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /healthy_sales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const healthy_sales_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"healthy_sales"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /export_healthy_sales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const healthy_sales_export_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"export_healthy_sales"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /healthy_sales/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const healthy_sales_new_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"healthy_sales"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /home(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /hygienist_average_productions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hygienist_average_productions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"hygienist_average_productions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /income_insurances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const income_insurances_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"income_insurances"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /inform_payment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inform_payment_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"inform_payment"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /leads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const leads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"leads"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /leads/dashboard_leads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const leads_dashboard_leads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"leads"],[2,[7,"/"],[2,[6,"dashboard_leads"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /leads_export(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const leads_export_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"leads_export"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /message_log(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const message_log_google_review_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"message_log"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /messenger(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const messenger_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"messenger"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /meta_ads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const meta_ads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"meta_ads"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /collections/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_collection_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /daily_productions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_daily_production_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"daily_productions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /employees/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_employee_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /inform_payment/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_inform_payment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"inform_payment"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /offices/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_office_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"offices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /new_patients(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_patients_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"new_patients"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /procedural_codes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_procedural_code_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"procedural_codes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sms_campaigns/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_sms_campaign_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sms_campaigns"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /notification_setting/profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notification_setting_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"notification_setting"],[2,[7,"/"],[2,[6,"profile"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /offices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const offices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"offices"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /office/show/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const offices_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"office"],[2,[7,"/"],[2,[6,"show"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /open_dental/break_appointment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const open_dental_break_appointment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"open_dental"],[2,[7,"/"],[2,[6,"break_appointment"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /open_dental/doctor_production(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const open_dental_doctor_production_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"open_dental"],[2,[7,"/"],[2,[6,"doctor_production"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /open_dental/list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const open_dental_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"open_dental"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /open_dental/patient_history/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const open_dental_patient_history_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"open_dental"],[2,[7,"/"],[2,[6,"patient_history"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /open_dental/patient_payment/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const open_dental_patient_payment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"open_dental"],[2,[7,"/"],[2,[6,"patient_payment"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /open_dental_payment_total(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const open_dental_payment_total_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"open_dental_payment_total"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /password_token/:token(.:format)
 * @param {any} token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_token_path = __jsr.r({"token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"password_token"],[2,[7,"/"],[2,[3,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /patient_dentrix_export(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_dentrix_export_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"patient_dentrix_export"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /patient_dentrix(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_dentrix_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"patient_dentrix"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /patient_forms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patient_forms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"patient_forms"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /patients(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const patients_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"patients"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /procedural_codes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const procedural_codes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"procedural_codes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /recovery_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recovery_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recovery_password"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /send_review(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_review_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"send_review"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_in_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /sms_campaigns(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sms_campaigns_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sms_campaigns"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /staff(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staff_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /2fa(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const two_factor_authentication_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"2fa"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /voicemails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const voicemails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"voicemails"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /webex(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webex_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webex"],[1,[2,[8,"."],[3,"format"]]]]]);

